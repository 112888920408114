<template>
  <div>
    <div class="flix-form-group">
      <label for="username">An wen möchten Sie diese Organisation teilen?</label>
      <div class="flix-input-group">
        <div class="flix-input-group-addon">
          <flixIcon v-if="checkedUsername" class="flix-text-success" id="ok" />
          <flixIcon v-else class="flix-text-danger" id="remove" />
        </div>
        <input id="username" type="text" class="flix-form-control" :placeholder="$t('message.add', { name: $t('message.username') })" v-model="username" spellcheck="false" />
      </div>
      Organisationen können Sie nur mit Personen teilen, die bereits einen Account bei Bookingflix eingerichtet haben.
    </div>

    <div class="flix-form-group" v-if="username && checkedUsername">
      <label for="role">{{ $t('message.role') }}</label>
      <select id="role" class="flix-form-control" v-model="variant">
        <option v-for="v in variants" :key="v" :value="v">{{ $t('message.' + v) }}</option>
      </select>
    </div>

    <div class="flix-form-group" v-if="username && checkedUsername">
      <saveBtn :onClick="addUser"></saveBtn>
    </div>

    <div class="flix-form-group" :key="variant" v-if="username && checkedUsername">
      <rules :variant="variant"/>
    </div>

    <div class="flix-form-group" v-if="username && checkedUsername">
      <saveBtn :onClick="addUser"></saveBtn>
    </div>
  </div>
</template>
<script>

export default {
  components: {
    rules () { return import('@/components/permissions/rules/index.vue') }
  },
  props: {
    callback: {
      type: Function,
      default () { return function () {} }
    }
  },
  data () {
    return {
      checkedUsername: false,
      username: '',
      variant: 'collaborateur',
      variants: ['abonnement', 'collaborateur', 'manager', 'admin']
    }
  },
  watch: {
    username (val) { this.checkUsername(val) }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setCheckUsername (ret) {
      this.checkedUsername = ret.data[0]
      if (ret.data[0] && this.username !== ret.data[1]) {
        this.username = ret.data[1]
      }
    },
    checkUsername (val) {
      this.$flix_post({
        url: 'organisation/member/check',
        data: {
          user: this.$store.getters.user.md5_id,
          name: val,
          organisation: this.$route.params.action
        },
        callback: this.setCheckUsername
      })
    },
    addUser () {
      this.callback(this.username, this.variant, this.$route.params.action)
    }
  }
}
</script>
<style lang="sass" scoped>
  select
    text-transform: uppercase
</style>
