<script>
import add from './add'
export default {
  extends: add,
  components: {},
  props: {
    edit: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {
    this.username = this.edit.username
    this.variant = this.edit.role
    this.checkUsername(this.username)
  },
  methods: {
    deleteUser () {
      this.$flix_post({
        url: 'organisation/member/delete',
        data: {
          user: this.$store.getters.user.md5_id,
          organisation: this.$route.params.action,
          member: this.edit.user
        },
        callback: this.insertUser
      })
    },
    insertUser () {
      this.$flix_post({
        url: 'organisation/member/set',
        data: {
          user: this.$store.getters.user.md5_id,
          name: this.username,
          rule: this.variant,
          organisation: this.$route.params.action
        },
        callback: this.setSave
      })
    },
    addUser () {
      this.deleteUser()
    },
    setSave () {
      this.$store.commit('updateOrganisationMembers')
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
